<template>
	<div class = "dashboard">
		<TopBar hidedropdown simple>
			<template v-slot:title>
				Boards
			</template>
			
		</TopBar>
		<main class="main-wrapper " style = "margin-top:21px;" >

			<span v-if="orgerror == 'orgdisabled'">This section is unavailable because the organization is currently inactive. You can re-activate it from via the organization settings.</span>

			<div class = "dashboardview" v-else>

				<b-modal header-class="align-items-center"  v-model="showorghelp" id ="myorginstructions2" size="md" hide-footer @hidden="showorghelpdismissed()" @shown="welcometoflowetextshown" scrollable>

					<template v-slot:modal-header>
					<h3 class="modal-title text-center">Welcome to Flowe</h3>
					<a class = "close-modal dialog-close-bustaketton mr-2">
					<i class="fal fa-times fa-lg" @click.stop.prevent="hideshoworghelp()"></i>
					</a>
					</template>

			        <div>
<p>Here are a few hints to help you get started:</p>

<p class="text-center"><img src="/static/img/welcome.png"></p>
<p>
The navigation menu includes the following:</p>
<ol style="font-weight: bold;">
	<li>Organization Menu: <span style="font-weight: normal;">Manage members, teams, and organizational settings. This is also where you can switch organizations or create new ones.</span></li>
	<li>Portfolios: <span style="font-weight: normal;">Track performance, manage risks, and prioritize investments across a group of projects.</span></li>
	<li>Boards: <span style="font-weight: normal;">This is the start page when you want to add a new project or access existing ones.</span></li>
	<li>Objectives: <span style="font-weight: normal;">Track the main goals of your projects. Use this area to update and communicate status.</span></li>
</ol>

<p>When you sign up with Flowe, a personal organization is created with you as the administrator. From your personal organization you can create new boards and objectives, and invite others to join. If you have been invited to a board belonging to another organization, then you must navigate to that organization in order to access it.</p>

<p>Your personal organization includes a ‘Getting started’ board which includes demo data to help you to familiarize yourself with Flowe’s features.</p>

<p>See the following links to get more help and guides on how to use Flowe:</p>
<ul>
<li><a href="/help/1386283017" target="_blank">5 easy steps to setting up Flowe <i class="fa-solid fa-arrow-up-right-from-square"></i></a></li>
<li><a href="/help" target="_blank">Get help on all the Flowe functions <i class="fa-solid fa-arrow-up-right-from-square"></i></a></li>
</ul>

			        	

						<p style="clear:both;">&nbsp;</p>

						<div class="text-right"><input type="checkbox" value="true" v-model="dontshowagain"> Don’t show this message again.</div>
						

			        </div>
		        </b-modal>


				<span v-if="loadtimer>5 && mode != 'idle'">
					<span class="m-2"><i class="fa fa-circle-o-notch fa-spin m-auto" style="font-size: 15px;"/> </span>
					
					<span v-if="loadtimer>25">This page is taking longer to load than usual...</span> 
					<a v-if="loadtimer>60" href="/">Try reloading?</a> 

				</span>
				<div class="cockpits cockpit_owned">
					<h4 :class="{'hide':mode=='working'}" class="alternate text-left mb-0 " ><span> <!-- <i class="far fa-user" aria-hidden="true"> </i> --> Boards where I am owner </span> </h4>
					
					<div class=" cockpit-row row">

						<template v-for="(cockpit, index) in cockpitsowned" >
							<cockpitCard :key="cockpit.id" :index = "index" :cockpit="cockpit" :token="token">
							</cockpitCard>
						</template>

						<div class="dashboarditemwrapper" style="opacity: .2" v-if="status=='posting'">
					    <a href="#">
					      <div class="card dashboarditem my-3" >
					        <div class="d-flex flex-row">
					          <div class="accent"></div> 
					          <div class="align-self-center w-75">
					           <div class="align-self-center card-title"> 
					            <span style ="word-break: break-word;"></span>
					          </div>

					          <div class="activity row justify-content-md-center">
					            <p class="col px-2" data-toggle="tooltip" data-placement="bottom" title="Comments">
					              <span><i class="fas fa-comments"></i>0</span>
					            </p>
					            <p class="col px-2" data-toggle="tooltip" data-placement="bottom" title="Likes">
					              <span><i class="fas fa-thumbs-up"></i>0</span>
					            </p>
					            <p class="col px-2" data-toggle="tooltip" data-placement="bottom" title="Dislikes">
					              <span><i class="fas fa-thumbs-down"></i>0</span>
					            </p>
					          </div>
					        </div>
					      </div>
					    </div>
					  </a>                          
					</div>

					<b-modal v-model="createnewboardmodalshow" @shown="oncreatenewboardmodalshown" @hidden="oncreatenewboardmodalhidden" id="createnewboardmodal" size="sm" no-close-on-backdrop no-close-on-esc >
			        
			        <template v-slot:modal-header>
			          <h3 class="modal-title">Create new board</h3>
			          <a class = "close-modal dialog-close-button mr-2">
			            <i class="fal fa-times fa-lg " @click="createnewboardmodalshow = false"></i>
			          </a>
			        </template>

			        <div class="mb-3">
			        	<label>Name</label><br>
			        	<input type="text" class="form-control" placeholder="Board name" v-model="newcockpitname" id="cockpitfromdashboardname" >
			        	<span class="text-danger" v-if="error!=''">&nbsp;{{error}}</span>
			        </div>


			        <div class="mb-2">
			        	Select optional modules to include on your board.
			        </div>
			        


			        <div class="mb-2 d-flex align-items-center">

					<div class="col-1 w-100 checkboxgroup">
						<span>
						<input type="checkbox" class="statusoptiondefaults" ref="check-defaultmasterplan" v-model="defaultmodules" value="masterplan">
						<label id="checkboxlabel" for="checkbox" tabindex=3></label>
						</span>
					</div>

					<div class="pt-2">Master plan</div>

					</div>

					<div class="mb-2 d-flex align-items-center">

					<div class="col-1 w-100 checkboxgroup">
						<span>
						<input type="checkbox" class="statusoptiondefaults" ref="check-defaultstakeholders" v-model="defaultmodules" value="stakeholder">
						<label id="checkboxlabel" for="checkbox" tabindex=3></label>
						</span>
					</div>

					<div class="pt-2">Stakeholders</div>

					</div>

					<div class="mb-2 d-flex align-items-center">

					<div class="col-1 w-100 checkboxgroup">
						<span>
						<input type="checkbox" class="statusoptiondefaults" ref="check-defaultrisks" v-model="defaultmodules" value="risk">
						<label id="checkboxlabel" for="checkbox" tabindex=3></label>
						</span>
					</div>

					<div class="pt-2">Risks</div>

					</div>

			        <div class="mb-2">
			        	These can also be added later via the board settings.
			        </div>

			        <template v-slot:modal-footer="{ cancel, ok }">

			        	<button class="btn btn-secondary" @click="createnewboardmodalshow=false">Cancel</button>

			        	<button v-if="newcockpitname==''" class="btn btn-primary disabled">Create board</button>
			        	<button v-else class="btn btn-primary" @click="docreatecockpit()">Create board</button>
			        	
			        </template>
		         			
				
	    			</b-modal>

						<div class="cockpitcards dashboarditemwrapper " id="createcockpit" v-if= "dashboardok" style = "cursor:pointer">
							<a class="card-block" @click="createcockpit()">
								<div class="card dashboarditem">
									<div class="text-center p-3">
										<i aria-hidden="true" class="fa fa-plus-circle"></i>
										<div class="text-center" style = "font-size:15px;">Create new board</div>
									</div>
								</div>
							</a>
						</div>

						 <cockpitsettingsmodal
						 v-if="newcockpit"
						 :checkforinvalidlink="false"
						 :cockpitid="newcockpitid" 
						 :loggedinuser="loggedinuser" 
						 :token="token" 
						 :shown="false" 
						 :myrole ="newcockpit.myrole"
						 :isactive="newcockpit.isactive"
						 ></cockpitsettingsmodal>

					</div>
				</div>

				<div class = "cockpits cockpit_shared">
					<h4 :class="{'hide':mode=='working'}" class="alternate text-left mb-0 mt-2" ><span><!-- <i class="far fa-user-friends" aria-hidden="true"></i> --> Boards I have access to  </span> </h4>
					<div class=" cockpit-row row" >
						<div class="brick col-lg-4 col-sm-6 p-0 clearfix mb-3 mt-2" v-if="cockpitsshared && cockpitsshared.length==0"><span class=" "> - there are no boards in this section - </span></div>
						<template v-else v-for="cockpit in cockpitsshared" >
							<cockpitCard :key="cockpit.id" :cockpit="cockpit" :token="token">
							</cockpitCard>
						</template>
					</div>
				</div>

				<div class = "cockpits cockpit_inactive">
					<h4 :class="{'hide':mode=='working'}" class="alternate text-left mb-0 mt-2" ><span><!-- <i class="far fa-user" aria-hidden="true"></i> --> Inactive boards where I am owner </span> </h4>
					<div class=" cockpit-row row "> 
						<div class="brick col-lg-4 col-sm-6 p-0 clearfix mb-3 mt-2" v-if="cockpitsinactive && cockpitsinactive.length==0"><span class=" "> - there are no boards in this section - </span></div>
						<template v-else v-for="cockpit in cockpitsinactive" >
							<cockpitCard :key="cockpit.id" :cockpit="cockpit" :token="token">
							</cockpitCard>
						</template>
					</div>
				</div>
			</div>
		</main>


		    

		
	</div>


</template>
<style type="text/css">

.modal-dialog-scrollable .modal-body{
	scrollbar-width: thin;
  	scrollbar-color: var(--N200);
}

ol.orghelpli {
   list-style: none;
   counter-reset: item;
 }
 .orghelpli li {
   counter-increment: item;
   margin-bottom: 5px;
 }

.orghelpli li:before {
   margin-right: 10px;
   content: counter(item);
   background: lightblue;
   border-radius: 100%;
   color: white;
   width: 1.2em;
   text-align: center;
   display: inline-block;
 }
	#myorginstructions___BV_modal_header_{
	border-bottom: none !important;
	}
</style>
<style lang ="scss" scoped>
@import '@/scss/abstracts/variable.scss';



</style>
<script type="text/javascript"></script>
<script>


	import Userapi from '@/services/api/users'
	import Cockpitsapi from '@/services/api/cockpits'
	import Dashboardapi from '@/services/api/dashboard'
	import Organizationsapi from '@/services/api/organizations'
	import {cockpitCard} from "./components"
	import {TopBar} from '@/components/topbar'

	import {cockpitsettingsmodal} from '@/views/cockpit/components'


	import introJs from 'intro.js/intro.js'

	import {bus} from '@/main'

	

	export default{
		name: "Dashboard",
		data(){
			return{
				createnewboardmodalshow:false,
				newcockpitid:"",
				newcockpitsettingsmodalshown:false,
				newcockpit:null,
				dontshowagain : false,
				loggedinuser : {},
				status : 'idle',
				defaultmodules:['masterplan','risk','stakeholder'],
				newcockpitname : "",
				cockpitsowned : null,
				cockpitsshared : {},
				cockpitsinactive : {},
				myobjectives : {},
				cockpits:[],
				mode: 'idle',
				loadtimer:0,
				loadingtimer:null,
				error : "",
				keys: {37: 1, 38: 1, 39: 1, 40: 1},
				dashboardok : false,
				orgerror:"",
				showorghelp : false,
				welcometoflowetext:"",
				welcometoflowetitle:""
			}

		},
		watch:{

			showorghelp( e ){

				if( e ){
					$('body').addClass('noscroll')
				}else{
					$('.noscroll').removeClass('noscroll')
				}

			},

      		loggedinuser( e, ev ){

      			let self = this

      			let nowvalue = this.loggedinuser.showorghelp ? parseInt(this.loggedinuser.showorghelp) : 1

      			let showorghelp = this.$cookie.get('showorghelp');

      			if( showorghelp === 'yes' && nowvalue > 0 && nowvalue < 5 ){
      				this.showorghelp = true

      				//Dashboardapi.Loadpage("2528116738").then( function( response ){

				 	//	if( response.data.page && response.data.page.body ){
				 			//self.welcometoflowetext = response.data.page.body
				 			//self.welcometoflowetitle = response.data.page.title

				 			/*setTimeout( function() {
				 				$( 'a', '#welcometoflowetext').attr('target', '_blank');
				 				$( 'a', '#welcometoflowetext').after('<i class="fa-solid fa-arrow-up-right-from-square"></i>')
				 			}, 300 )*/
				 			
				 	//	}
				 	//})

      			}

      		},


      	},
		computed:{


			imatmyorg(){
//				return this.organization.defaultuser == this.loggedinuser._id
				return true
			},

			token(){
		    	return localStorage.token
		  	},

			organization(){
		      return this.$store.state.organizations.selectedorganizations
		    },

		    orgkey(){
		      return this.$route.params.orgname ? this.$route.params.orgname : null
		    },

		    validorg(){

		      if( this.organization.key && ( this.$route.params.orgname != this.organization.key )  ){
		        return false
		      }else{
		        return true
		      }


		    },

			orgname(){
	          if( this.$route.params.orgname ){
	            return this.$route.params.orgname
	          }else{
	          	return ""
	          }
	        },

			newcockpitnamevailde(){
				var toreturn = true
				if (!this.newcockpitname.replace(/\s/g, '').length){
					toreturn = false
				}

				return !toreturn
			}
		},
		mounted() {

			let self = this

			this.preload()

			if( this.$route.query.showmodal ){
		 		

		 		Dashboardapi.Loadpage("2528116738").then( function( response ){

				 		if( response.data.page && response.data.page.body ){
				 			
				 			self.showorghelp = true

				 			self.welcometoflowetext = response.data.page.body
				 			self.welcometoflowetitle = response.data.page.title

				 			/*setTimeout( function() {
				 				$( 'a', '#welcometoflowetext').attr('target', '_blank');
				 				$( 'a', '#welcometoflowetext').after('<i class="fa-solid fa-arrow-up-right-from-square"></i>')
				 			}, 300 )*/
				 		}
				 	})
		 	} 
      },

      methods:{

      	oncreatenewboardmodalshown(){


      	},

      	oncreatenewboardmodalhidden(){


      	},

      	welcometoflowetextshown(){

      		setTimeout( function() {
 				$( 'a', '#welcometoflowetext').attr('target', '_blank');
 				$( 'a', '#welcometoflowetext').append('<i class="ml-2 fa-solid fa-arrow-up-right-from-square"></i>')
 			}, 300 )

      	},

      	hideshoworghelp(){

      		
      		this.showorghelp = false
      		//this.doshoworghelp()

      	},


      	showorghelpdismissed(){

      		this.$cookie.set('showorghelp', 'no');
      		let self = this

      		let nowvalue = this.loggedinuser.showorghelp ? parseInt(this.loggedinuser.showorghelp) : 1
      		let newvalue = nowvalue + 1

      		if( newvalue > 5 ){
      			newvalue = 0
      		}

      		if( this.dontshowagain == true ){

      			Userapi.Updateprofile( { "userid":this.loggedinuser._id, "field":'showorghelp', "value":0, "t":this.token } )
      			
      		}else{

				//Userapi.Updateprofile( { "_id":this.loggedinuser._id, "showorghelp":"no"}  )

				Userapi.Updateprofile( { "userid":this.loggedinuser._id, "field":'showorghelp', "value":parseInt(newvalue), "t":this.token } )
      		}

      	
      	},

      	preload(){


				let self = this

				//alert('')


				this.loadingtimer = setInterval( function(){
					
					if( self.mode == 'working' ){
						self.loadtimer++
					}

					if( self.loadtimer > 8 ){
						//location.reload();
						//alert('')
						self.$forceUpdate(); 
						//clearInterval(self.loadingtimer)
						//self.loadtimer = 0
						//self.preload()

					}
				}, 1000);

			/*setTimeout( function(){

				if( self.mode == 'working' )

			}, 15000)*/

			//alert('cc')

			//alert( this.$route.params.orgname )
			//console.log('hello')
				document.title ='Boards | Flowe'
				this.mode = "working"

				$('#preloader').removeClass('hide')

				// console.log('dashboard/index mounted') 

			
				$(function () {
					$('[data-toggle="tooltip"]').tooltip()
				})

				/*$('html').on('click',function() {
					self.status = 'idle'
					self.newcockpitname = ''

				});*/

				//localStorage.token = this.$route.params.token

				self.loggedinuser = this.$store.state.user.userInfo



				$('.modal').modal('hide');
				$('.modal-backdrop').remove();
				$('body').removeClass('modal-open')		
         
          
         	bus.$off('ondashboardloaded')
         	bus.$on('ondashboardloaded', this.dashboardloaded )

         	//bus.$off('showorghelp')
         	//bus.$on('showorghelp', this.doshoworghelp )
          

          	//this.doshoworghelp()


      	},

      	newcockpitnamecomposing( ev ){
      		this.newcockpitname = ev.target.value
      	}, 
      	
      	dashboardloaded( dashboard ){

      		this.orgerror = ""

      		if( dashboard.orgerror ){
      			this.orgerror = dashboard.orgerror
      		}


      		if( !dashboard.cockpitsowned ){
      			this.dashboardok = false
      		}

      		this.dashboardok = true

      		this.mode = "idle"

      		this.cockpitsowned = dashboard.cockpitsowned
      		this.cockpitsshared = dashboard.cockpitssharedtome 
      		this.cockpitsinactive = dashboard.myinactivecockpits
      		this.myobjectives = dashboard.mymeasuresoverview
      		this.showhints = dashboard.showhints

      		if( this.$route.params.myobjectives != "myobjectives" ){
      			$('#preloader').addClass('hide')
      		}

      		if( this.validorg == false ){

      			//this.$cookie.set('organizationkey', this.organization.key);
      			//location.href='/'+this.organization.key+'/boards'
      		}

      		//var organizationkey = this.$cookie.get('organizationkey');
      		//alert(organizationkey)
      	},
      	getstatede(){

      	},

      	createcockpit(){
      		this.error = ""
      		this.status = 'createcockpit';
      		this.createnewboardmodalshow = true
      		let self = this
      		this.$nextTick(function() {
      			document.getElementById('cockpitfromdashboardname').focus();
      			$(document).on('keypress',function(e) {
				    if(e.which == 13 && !self.newcockpitnamevailde) {
				    		self.docreatecockpit()
				    }
				});

      			$('#createcockpitopen').on('click',function(event) {
      				event.stopPropagation();
      			})

      		})
      	},

      	docreatecockpit(){

      		if( this.validorg ){

	      		let self = this

	      		this.status = 'posting'

	      		var roles = []
	      		var nodes = []
	      		
	      		roles.push(JSON.stringify( { "userid":self.loggedinuser._id, "role":"Owner"}))


	      		//console.log( this.validorg  , "leaning")

	      		Cockpitsapi.Createnew( { "org":this.orgname, "t":localStorage.token, "cockpitdescription":"", "cockpitshortdescription":"", "cockpitname": self.newcockpitname, "nodes": nodes, "modules": JSON.stringify(this.defaultmodules), "users[]":  roles } )
	      		.then( function(response){

	      			if( response.data.ok ){

	      				self.createnewboardmodalshow = false

	      				self.newcockpitid = response.data.cockpit._id
	      				self.newcockpit = response.data.cockpit

	      				//self.$nextTick(function(){
	      				//	console.log( response.data.cockpit , "zzzzzz")
	      				//	bus.$emit('onboardcreated', response.data.cockpit, { "masterplan": self.defaultmasterplan, "stakeholders": self.defaultstakeholders, "risks": self.defaultrisks } )


	      				//})

	      				

	      				self.loadDashboard({"reload":true,"add":response.data.cockpit})
	      			}else{

	      				if(  response.data.errors.orgrole == 'noaccess' ){

	      					self.error = "Sorry. It looks like you no longer have access to this. Please try again or contact your IT administrator if the error persists."

	      				}else{
	      					
	      					self.error = "Error creating your board, please try again."
	      				}

	      				self.status = 'createcockpit'
	      				
	      			}

	      		})
	      		.catch(error => {
	      			self.status = 'createcockpit'
	      			self.error = "Error creating your board, please try again."
	      		})
	      		.finally( () => {
	      			self.newcockpitname = '';
	      			$(document).off('keypress');
	      		})

      		}

      	},

      	getuser( callback ){


      		let self = this

      		Userapi.Details( localStorage.token )
      		.then( function(response){

      			if( response.data.ok ){
      				callback()
      			}

      		})
      		.catch(error => console.log(error))
      		.finally( () => {

      			self.status = 'idle'

              //do everything
              //self.signin  = { password:"", signinisvalid:true, emailisvalid:true, signinlocked:false, passwordempty:false }
          })
      	},

      	loadDashboard( payload ){

      		let self = this		

      		if( !payload.reload ){

      			this.cockpitsowned = []
	            this.cockpitsshared = []
	            this.cockpitsinactive = []
	            this.myobjectives = []

      		}

			

            this.dashboardok = false

            //

      		Dashboardapi.Mydashboarditems( localStorage.token, this.orgname )
      		.then( function(response){



      			if( response.data.ok ){

      				self.dashboardok = true
	                //console.log('cockpits', response.data )
	                self.cockpitsowned = response.data.cockpitsowned
	                self.cockpitsshared = response.data.cockpitssharedtome 
	                self.cockpitsinactive = response.data.myinactivecockpits
	                self.myobjectives = response.data.mymeasuresoverview
	                
	            }else{

	            	/*console.log( response.data )

	            	 if( response.data.orgerror == "disableduser" ){
			           //sself.$router.push("/403")
			        }*/
			        
	            }

	        })
      		.catch(error => console.log(error))
      		.finally( () => {

      			self.status = 'idle'

              //do everything
              //self.signin  = { password:"", signinisvalid:true, emailisvalid:true, signinlocked:false, passwordempty:false }
          })




      	},
      	modeCreate(){
      		this.mode = 'createcockpit'
      	},

      	modeIdle(){
      		this.mode='idle'
      	}
      },

      components:{
      	cockpitsettingsmodal,
      	cockpitCard,
      	TopBar
      },
      updated(){
      	//console.log('update')
      }
  }
</script>